<template>
  <div>
    <b-button
      variant="primary"
      @click="informationModal"
      :disabled="disabled.edit"
      class="mr-2"
      >Related</b-button
    >
    <b-modal
      v-model="showModal.information"
      centered
      no-close-on-backdrop
      scrollable
      title="Edit"
      id="void_target"
      ref="modal"
      size="lg"
    >
      <b-overlay :show="loading">
        <b-tabs v-model="tabs" content-class="mt-3 mb-10" lazy>
          <b-tab title="Information">
            <b-row class="mb-5">
              <b-col md="12" class="mt-5 mb-3">
                <h5 class="subtitle">Related WHOLESALE / DEPOSIT Purchase</h5>
              </b-col>
              <b-col md="12" class="my-1">
                <b-form-group
                  class="refundable required"
                  label-for="refundable"
                  label-cols-sm="2"
                  label-cols-lg="2"
                  label-align-sm="right"
                  label-size="sm"
                  label="Purchase"
                >
                  <sv-select
                    @search="onSearch"
                    class="select-mh-9"
                    v-model="save.purchase_over_view_relation_id"
                    placeholder="Please Type to Select"
                    :options="options.purchase_over_view_relation_id"
                    multiple
                  >
                    <template slot="selected-option" slot-scope="option">
                      {{ option.label.split('-')[0] }}
                    </template>
                  </sv-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Logs">
            <SimpleTable
              :items="items"
              :fields="fields"
              :tableId="'logs'"
              style="margin-top: 20px; min-height: 200px"
            ></SimpleTable>
          </b-tab>
        </b-tabs>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button variant="primary" @click="submit()" :disabled="disabled">
          Submit
        </b-button>
        <b-button variant="secondary" @click="close()">
          {{ $t('page.cancel') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import apiBus from '@/common/apiBus/index';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
import SimpleTable from '@/components/Table/Simple.vue';
// import { RENEW_BASE_CONFIG } from '@/store/modules/baseconfig/actions';
export default {
  name: 'RelatedPurchase',
  data: function() {
    return {
      tabs: 0,
      showModal: {
        information: false
      },
      disabled: false,
      save: {
        purchase_over_view_relation_id: null
      },
      loading: false,
      items: [],
      fields: [
        {
          key: 'created_at',
          label: 'Time'
        },
        {
          key: 'system_user',
          label: 'System User'
        },
        {
          key: 'log_action',
          label: 'Action'
        },
        {
          key: 'detail',
          label: 'Details'
        }
      ],
      options: {
        purchase_over_view_relation_id: []
      }
    };
  },
  components: { SimpleTable },
  props: ['id', 'initFn'],
  methods: {
    //   删除
    initialTime(obj, name) {
      obj[name] = null;
    },
    onSearch: function(search, loading) {
      if (search != null && search != '') {
        loading(true);
        this.search(loading, search, this);
      }
    },
    // 做放抖动处理
    search: _.debounce((loading, search, vm) => {
      apiBus.uploadInvoice
        .getSelectRelatePurchaseOverViewList({ keyWord: search })
        .then(data => {
          vm.options.purchase_over_view_relation_id = data.data.data;
          loading(false);
        })
        .catch(error => {
          console.log(error);
          vm.options.purchase_over_view_relation_id = [];
          loading(false);
        });
    }, 350),

    informationModal() {
      this.showModal.information = true;
      this.loading = true;
      this.disabled = true;
      apiBus.uploadInvoice
        .getRelatePurchaseOverView({ purchase_over_view_id: this.id })
        .then(data => {
          this.loading = false;
          this.disabled = false;
          this.options.purchase_over_view_relation_id = data.data.data;
          this.save.purchase_over_view_relation_id = data.data.data;
        })
        .catch(error => {
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
      apiBus.uploadInvoice
        .getRelatePurchaseOverViewLogList({ purchase_over_view_id: this.id })
        .then(data => {
          this.items = data.data.data;
        })
        .catch(error => {
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    submit() {
      if (this.save.purchase_over_view_relation_id.length == 0) {
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 5, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'Fields marked with * are required!' // 提示内容
        });
        return false;
      }
      this.loading = true;
      this.disabled = true;
      let purchase_over_view_relation_id_arr = [];
      let data = {
        purchase_over_view_id: this.id
      };
      this.save.purchase_over_view_relation_id.forEach(element => {
        purchase_over_view_relation_id_arr.push(element.value);
      });

      data.purchase_over_view_relation_id = purchase_over_view_relation_id_arr;

      apiBus.uploadInvoice
        .relatePurchaseOverView(data)
        .then(data => {
          console.log(data);
          this.showModal.information = false;
          this.loading = false;
          this.disabled = false;
          this.initFn();
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
        })
        .catch(error => {
          // this.showModal.information = false;
          this.loading = false;
          this.disabled = false;
          // this.initFn();
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },

    init() {}
  },
  watch: {},
  computed: {
    ...mapState({
      baseConfig: state => state.baseconfig.baseconfig
    })
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped></style>
